import React from 'react';
import DigiBirdComponentCard from 'components/common/DigiBirdComponentCard';
import PageHeader from 'components/common/PageHeader';

const DesignFile = () => (
  <>
    <PageHeader title="Design File" className="mb-3">
      <p className="mt-2 mb-0">
        DigiBird Theme có kèm theo File Thiết Kế. Liên hệ các bạn leader để nhận
        file thiết kế
      </p>
    </PageHeader>

    <DigiBirdComponentCard>
      <DigiBirdComponentCard.Body>
        <p>
          This is an early demo of Figma files. All the design is not yet
          recreated with Figma. But hopefully, we will port all the layouts in
          the next couple of weeks.
        </p>
        <h5>To play with the design:</h5>
        <ul>
          <li>
            <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
              Download Figma
            </a>
          </li>
          <li>
            Open the Figma link from the <code>README.md</code>
          </li>
          <li>
            The file is "read-only", so you'll have to duplicate it! Go to your
            grid of documents in Figma, click the ellipsis icon on{' '}
            <b className="text-primary text-600">DigiBird (Distributed)</b>, and
            then "Duplicate" to create an editable version.
          </li>
        </ul>
      </DigiBirdComponentCard.Body>
    </DigiBirdComponentCard>
  </>
);

export default DesignFile;
